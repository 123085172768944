<template>
  <div class="login_wrap">
    <!--    蓝灯鱼logo-->
    <div class="login_logo">
      <img src="@/assets/images/login/new_logo.png" alt/>
    </div>

    <dl class="qrCode">
      <dt class="translate">
        <el-image :style="{borderRadius: qrCodeImg ? '50%' : '0'}" :src="qrCodeImg">
          <div style="width: 100px;border-radius: 0" slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
        </el-image>
      </dt>
      <dd class="scan"></dd>
      <dd class="signIn">微信扫码登陆</dd>
    </dl>

    <!--    非微信登录-->
    <div :class="['login_phone',{'active': register}]" v-show="!wechatLogin || (wechatLogin && wechatAuth)">
      <ul class="menu" v-if="userName || quickLogin">
        <li :class="[{'active': activeFlag}]" @click="switchTable(1)">
          账号密码登录
        </li>
        <li :class="[{'active': !activeFlag}]" @click="switchTable(2)">
          手机快捷登录
        </li>
      </ul>
      <div :class="['logon_main',{'active': register}]">
        <!--      用户名登录-->
        <div v-if="userName">
          <el-form key="1" v-if="!wechatLogin && !wechatAuth" :model="ruleForm" :rules="AccountLoginRules"
                   ref="ruleForm1" class="login_form" @submit="submitForm('ruleForm1')">
            <el-form-item prop="phone">
              <el-input class="regionCode" v-model="ruleForm.phone" auto-complete="off" :placeholder="$t(`logins.fild[0]`)"
                         @keyup.enter.native="submitForm('ruleForm1')">
                <template slot="prepend">

                  <el-dropdown @command="handleCommand">
                    <span style="display: flex;align-items: center;margin-top: 6px" class="el-dropdown-link">
                      <img style="height: 27px" :src="regionCodeObject.srcImg" alt=""> <span style="font-size: 16px;margin-left: 5px"> +{{regionCodeObject.code}}</span> <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item  command="86">86(中国大陆)</el-dropdown-item>
                      <el-dropdown-item  command="852">852(中国香港)</el-dropdown-item>
                      <el-dropdown-item  command="853">853(中国澳门)</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
<!--                <el-select class="456" v-model="selectVal" slot="prepend" placeholder="请选择">-->
<!--                  <el-option label="86(中国大陆)" value="86"></el-option>-->
<!--                  <el-option label="852(中国香港)" value="852"></el-option>-->
<!--                  <el-option label="853(中国澳门)" value="853"></el-option>-->
<!--                </el-select>-->
              </el-input>
            </el-form-item>
            <el-form-item prop="password" class="login_code">
              <el-input type="password" v-model="ruleForm.password" auto-complete="off"
                        :placeholder="$t(`logins.fild[1]`)"
                        @keyup.enter.native="login('ruleForm1')"></el-input>
            </el-form-item>
            <el-form-item v-show="!wechatLogin">
              <el-button class="login_btn" @click="login()" :loading="loading>0" :disabled="disabledBtn">
                登录
              </el-button>
            </el-form-item>
            <el-form-item v-show="wechatLogin && wechatAuth">
              <el-button class="login_btn" @click="submitForm('ruleForm1')" :loading="loading>0"
                         :disabled="disabledBtn">
                {{$t('headers.vxLogin')}}
              </el-button>
            </el-form-item>
            <el-form-item v-show="!wechatLogin" :class="['treaty',{'active': !isCN}]">
              <ul>
                <li @click="Register">{{$t(`logins.fild[2]`)}}</li>
                <!--                <li @click="Forgetpassword">{{$t(`logins.fild[3]`)}}</li>-->
                <!--                <li ;>{{$t(`logins.fild[4]`)}}</li>-->
              </ul>
              <!--          <el-checkbox v-show="!wechatLogin" v-model="checked"></el-checkbox>-->
              <!--          <a v-show="!wechatLogin" target="_blank" href="/legal/term">{{$t('logins.AgreementBtn')}}</a>-->
              <!--              <p v-show="wechatLogin && wechatAuth">{{$t('logins.wechatDesc')}}</p>-->
            </el-form-item>
          </el-form>

          <el-form key="10" v-else :model="ruleForm" :rules="rules" ref="ruleForm10" class="login_form"
                   @submit="submitForm('ruleForm10')">
            <el-form-item prop="phone">
              <el-input v-model="ruleForm.phone" auto-complete="off" :placeholder="$t(`logins.form.phone.placeholder`)"
                         @keyup.enter.native="submitForm('ruleForm10')"></el-input>
            </el-form-item>
            <el-form-item prop="code" class="login_code">
              <el-input v-model="ruleForm.code" auto-complete="off" :placeholder="$t(`logins.form.code.placeholder`)"
                        maxlength="6" @keyup.enter.native="submitForm('ruleForm10')"></el-input>
              <el-button class="login_code_btn" :disabled="codeDisabled" @click="useVerify('ruleForm10')">{{codeMsg}}
              </el-button>
            </el-form-item>
            <el-form-item v-show="!wechatLogin">
              <el-button class="login_btn" @click="submitForm('ruleForm10')" :loading="loading>0"
                         :disabled="disabledBtn">
                {{$t('headers.login')}}
              </el-button>
            </el-form-item>
            <el-form-item v-show="wechatLogin && wechatAuth">
              <el-button class="login_btn" @click="submitForm('ruleForm10')" :loading="loading>0"
                         :disabled="disabledBtn">
                {{$t('headers.vxLogin')}}
              </el-button>
            </el-form-item>
            <!--            <el-form-item class="treaty">-->
            <!--              <el-checkbox v-show="!wechatLogin" v-model="checked"></el-checkbox>-->
            <!--              <a v-show="!wechatLogin" target="_blank" href="/legal/term">{{$t('logins.AgreementBtn')}}</a>-->
            <!--              <p v-show="wechatLogin && wechatAuth">{{$t('logins.wechatDesc')}}</p>-->
            <!--            </el-form-item>-->
          </el-form>
        </div>

        <!--      立即注册-->
        <div v-if="register">
          <el-form
            key="2"
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm2"
            class="login_form"
            @submit="submitForm('ruleForm2')"
          >
            <el-form-item prop="phone">
              <el-input
                v-model="ruleForm.phone"
                auto-complete="off"
                :placeholder="$t(`logins.fild[0]`)"

              >
                <template slot="prepend">

                  <el-dropdown @command="handleCommand">
                    <span style="display: flex;align-items: center;margin-top: 6px" class="el-dropdown-link">
                      <img style="height: 27px" :src="regionCodeObject.srcImg" alt=""> <span style="font-size: 16px;margin-left: 5px"> +{{regionCodeObject.code}}</span> <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item  command="86">86(中国大陆)</el-dropdown-item>
                      <el-dropdown-item  command="852">852(中国香港)</el-dropdown-item>
                      <el-dropdown-item  command="853">853(中国澳门)</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-input>
            </el-form-item>

            <el-form-item prop="password">
              <el-input
                type="password"
                v-model="ruleForm.password"
                auto-complete="off"
                :placeholder="$t(`logins.fild[5]`)"

              />
            </el-form-item>

            <el-form-item prop="remark">
              <el-input
                v-model="ruleForm.remark"
                auto-complete="off"
                placeholder="推荐人(选填)"
              />
            </el-form-item>

            <el-form-item style="margin-top: -6px" prop="code" class="login_code">
              <el-input
                v-model="ruleForm.code"
                auto-complete="off"
                :placeholder="$t(`logins.form.code.placeholder`)"
                maxlength="6"
                @keyup.enter.native="submitForm('ruleForm2')"
              />
              <el-button
                class="login_code_btn"
                :disabled="codeDisabled"
                @click="useVerify('ruleForm2')"
              >
                {{codeMsg}}
              </el-button>
            </el-form-item>

            <el-form-item v-show="!wechatLogin">
              <el-button
                class="login_btn"
                @click="isRegister('ruleForm2')"
                :loading="loading>0"
                :disabled="disabledBtn"
              >
                {{$t('logins.fild[6]')}}
              </el-button>
            </el-form-item>

            <el-form-item v-show="wechatLogin && wechatAuth">
              <el-button
                class="login_btn"
                @click="submitForm('ruleForm2')"
                :loading="loading>0"
                :disabled="disabledBtn"
              >
                {{$t('headers.vxLogin')}}
              </el-button>
            </el-form-item>
            <el-form-item  class="treaty active">
              <el-checkbox style="margin-top: -2px;margin-right: 10px" v-show="!wechatLogin" v-model="checked"></el-checkbox>
              <p style="margin-top: 8px;cursor: pointer;color: #606266;" v-show="!wechatLogin"  @click="jumpTab">我已阅读并同意 <span style="color: #2642AD;">《蓝灯鱼法律声明及隐私政策》</span>和 <span style="color: #2642AD;">《蓝灯鱼用户服务协议》</span></p>
              <p v-show="wechatLogin && wechatAuth">{{$t('logins.wechatDesc')}}</p>
            </el-form-item>
          </el-form>
        </div>

        <!--      忘记密码-->
        <div v-if="forgetPassword">
          <el-form key="3" :model="ruleForm" :rules="rules" ref="ruleForm3" class="login_form">
            <el-form-item prop="phone">
              <el-input v-model="ruleForm.phone" auto-complete="off" :placeholder="$t(`logins.fild[0]`)"
                        >
                <template slot="prepend">

                  <el-dropdown @command="handleCommand">
                    <span style="display: flex;align-items: center;margin-top: 6px" class="el-dropdown-link">
                      <img style="height: 27px" :src="regionCodeObject.srcImg" alt=""> <span style="font-size: 16px;margin-left: 5px"> +{{regionCodeObject.code}}</span> <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item  command="86">86(中国大陆)</el-dropdown-item>
                      <el-dropdown-item  command="852">852(中国香港)</el-dropdown-item>
                      <el-dropdown-item  command="853">853(中国澳门)</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="code" class="login_code">
              <el-input v-model="ruleForm.code" auto-complete="off" :placeholder="$t(`logins.form.code.placeholder`)"
                        maxlength="6"></el-input>
              <el-button class="login_code_btn" :disabled="codeDisabled" @click="useVerify('ruleForm3')">{{codeMsg}}
              </el-button>
            </el-form-item>
            <el-form-item v-show="!wechatLogin">
              <el-button class="login_btn" @click="goSetpassword('ruleForm3')" :loading="loading>0"
                         :disabled="disabledBtn">
                {{$t(`logins.fild[7]`)}}
              </el-button>
            </el-form-item>
          </el-form>
        </div>

        <!--      设置密码-->
        <div v-if="setPassword">
          <el-form key="4" :model="ruleForm" :rules="rule" ref="ruleForm4" class="login_form">
            <el-form-item prop="password">
              <el-input type="password" v-model="ruleForm.password" auto-complete="off"
                        :placeholder="$t(`logins.fild[5]`)"
              ></el-input>
            </el-form-item>
            <el-form-item prop="repeatpassword" class="login_code">
              <el-input type="password" v-model="ruleForm.repeatpassword" auto-complete="off"
                        :placeholder="$t(`logins.fild_sub[0]`)"></el-input>
            </el-form-item>
            <el-form-item v-show="!wechatLogin">
              <el-button class="login_btn" @click="RSetpasswordSuccess('ruleForm4')" :loading="loading>0"
                         :disabled="disabledBtn">
                {{$t(`logins.fild_sub[1]`)}}
              </el-button>
            </el-form-item>
          </el-form>
        </div>

        <!--      快捷登录-->
        <div v-if="quickLogin">
          <el-form key="5" :model="ruleForm" :rules="rules" ref="ruleForm4" class="login_form"
                   @submit="submitForm('ruleForm4')">
            <el-form-item prop="phone">
              <el-input v-model="ruleForm.phone" auto-complete="off" :placeholder="$t(`logins.fild[0]`)"
                         @keyup.enter.native="submitForm('ruleForm4')">
                <template slot="prepend">

                  <el-dropdown @command="handleCommand">
                    <span style="display: flex;align-items: center;margin-top: 6px" class="el-dropdown-link">
                      <img style="height: 27px" :src="regionCodeObject.srcImg" alt=""> <span style="font-size: 16px;margin-left: 5px"> +{{regionCodeObject.code}}</span> <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item  command="86">86(中国大陆)</el-dropdown-item>
                      <el-dropdown-item  command="852">852(中国香港)</el-dropdown-item>
                      <el-dropdown-item  command="853">853(中国澳门)</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="code" class="login_code">
              <el-input v-model="ruleForm.code" auto-complete="off" :placeholder="$t(`logins.form.code.placeholder`)"
                        maxlength="6" @keyup.enter.native="submitForm('ruleForm4')"></el-input>
              <el-button class="login_code_btn" :disabled="codeDisabled" @click="useVerify('ruleForm4')">{{codeMsg}}
              </el-button>
            </el-form-item>
            <el-form-item v-show="!wechatLogin">
              <el-button class="login_btn" @click="submitForm('ruleForm4')" :loading="loading>0"
                         :disabled="disabledBtn">
                {{$t(`logins.fild_sub[2]`)}}
              </el-button>
            </el-form-item>
            <el-form-item v-show="wechatLogin && wechatAuth">
              <el-button class="login_btn" @click="submitForm('ruleForm4')" :loading="loading>0"
                         :disabled="disabledBtn">
                {{$t('headers.vxLogin')}}
              </el-button>
            </el-form-item>
            <el-form-item :class="['treaty',{'active': !isCN}]">
              <ul>
                <li @click="Register">{{$t(`logins.fild[2]`)}}</li>
                <!--                <li @click="Forgetpassword">{{$t(`logins.fild[3]`)}}</li>-->
                <!--                <li @click="BackLogin">{{$t(`logins.fild_sub[3]`)}}</li>-->
              </ul>
              <!--          <el-checkbox v-show="!wechatLogin" v-model="checked"></el-checkbox>-->
              <!--          <a v-show="!wechatLogin" target="_blank" href="/legal/term">{{$t('logins.AgreementBtn')}}</a>-->
              <p v-show="wechatLogin && wechatAuth">{{$t('logins.wechatDesc')}}</p>
            </el-form-item>
          </el-form>
        </div>
      </div>


      <!--      注册成功-->
      <div v-if="loginSuccessful">
        <div class="loginSuccessful">
          <i class="">
            <img v-if="registerSuccess" src="@/assets/images/common/successful.png" alt="">
            <img v-else src="@/assets/images/common/mima.png" alt="">
          </i>
          <span>{{registerSuccess ? $t(`logins.fild_sub[4]`) : $t(`logins.fild_sub[5]`)}}</span>
          <el-button class="login_btn" @click="BackLogin" :loading="loading>0" :disabled="disabledBtn">
            {{$t(`logins.fild_sub[6]`)}}</el-button>
        </div>

      </div>
    </div>

    <!--    微信登录-->
    <dl class="login_wechat" v-show="wechatLogin && !wechatAuth">
      <dt id="qrcode">
        <!-- <div id="qrcode"></div> -->
      </dt>
      <dd>{{$t(`logins.tips`)}}</dd>
    </dl>

    <div class="accout" v-if="register">
      <p>{{$t(`logins.fild_sub[7]`)}}<span @click="BackLogin">登录</span></p>
    </div>
    <Verify
      @success="success"
      :mode="'pop'"
      :captchaType="'blockPuzzle'"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
    ></Verify>
<!--    <button @click="useVerify">调用验证组件</button>-->
  </div>
</template>

<script>
  import {mapState,mapMutations} from "vuex";
  import {SMS, VXCHECK, account, regist, smsVerification, retrieve, loginCheck} from "@/request/login";
  import Verify from "@/components/verifition/Verify";
  import {USER, NOTIFY} from "@/request/common";
  import {adSearchLog} from "@/request/patentRequest";
  import {encryption} from '@/util'
  import qs from 'qs'

  export default {
    name: "login",
    props: ["dialogClose"],
    components: {Verify},
    data() {
      // 表单验证规则
      // 校验手机号码
      var phoneReg = /^[1][0-9]{10}$/,
        codeReg = /^\d{6}$/;
      var validatePhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t("logins.form.phone.placeholder")));
        }
        setTimeout(() => {
          if (!value) {
            callback(new Error(this.$t("logins.form.phone.errorMessage")));
          } else {
            callback();
          }
        }, 1000);
      };
      // 校验验证码
      var validateCode = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t("logins.form.code.placeholder")));
        }
        setTimeout(() => {
          if (!codeReg.test(value)) {
            callback(new Error(this.$t("logins.form.code.errorMessage")));
          } else {
            callback();
          }
        }, 1000);
      };
      //校验账户 AccountLoginRules
      var AccountRules = (rule, value, callback) => {
        if (!value) {
          return callback(new Error(this.$t("logins.form.phone.placeholder")));
        }
        callback();
      };

      return {
        regionCodeObject: {
          srcImg: '/images/national/zgdl.jpg',
          code: '86'
        },
        selectVal: "86",
        //token 令牌
        Authorization: "Basic YXBwOmFwcA==",
        flag:'',
        //小程序二维码
        qrCodeImg: '',
        //扫码成功
        checkTimer: null,
        activeFlag: true,
        //中文
        isCN: true,
        //是否是已注册用户
        isName: true,
        //记录用户手机号
        Reiphone: null,
        //设置验证令牌
        Retoken: null,
        //注册成功
        registerSuccess: false,
        //设置密码
        setPassword: false,
        //注册成功
        loginSuccessful: false,
        //用户名登录
        userName: true,
        //立即注册
        register: false,
        //忘记密码
        forgetPassword: false,
        //快捷登录
        quickLogin: false,
        qrcodeObj: {},
        // 微信登录
        wechatLogin: false,
        // 微信授权
        wechatAuth: false,
        // 是否禁用按钮
        codeDisabled: false,
        disabledBtn: false,
        // 倒计时秒数
        countdown: 60,
        // 按钮上的文字
        codeMsg: this.$t("logins.form.sendCodeText"),
        // 定时器
        timer: null,
        // 是否选择协议
        checked: false,
        // 表单参数
        ruleForm: {
          phone: '',
          code: '',
          password: undefined,
          repeatpassword: '',
          remark:''
        },
        // 随机码（自动生成一串随机码）  后台传的参
        token: Math.random(),
        //账号密码登录验证
        AccountLoginRules: {
          phone: [{required: true, validator: AccountRules, trigger: "blur"}],
          code: [
            {
              required: true,
              validator: validateCode,
              trigger: "blur"
            },
          ],
          password: [
            {required: true, message: this.$t(`logins.fild[5]`), trigger: 'blur'},
            {min: 6, message: this.$t(`logins.fild[5]`), trigger: 'blur'}
          ]
        },
        // 快捷登录表单验证
        rules: {
          phone: [{required: true, validator: validatePhone, trigger: "blur"}],
          code: [
            {
              required: true,
              validator: validateCode,
              trigger: "blur"
            },
          ],
          password: [
            {required: true, message: this.$t(`logins.fild[5]`), trigger: 'blur'},
            {min: 6, message: this.$t(`logins.fild[5]`), trigger: 'blur'}
          ]
        },
        // 表单注册验证
        rule: {
          password: [
            {required: true, message: this.$t(`logins.fild[5]`), trigger: 'blur'},
            {min: 6, message: this.$t(`logins.fild[5]`), trigger: 'blur'}
          ],
          repeatpassword: [
            {required: true, message: this.$t(`logins.fild[5]`), trigger: 'blur'},
            {min: 6, message: this.$t(`logins.fild[5]`), trigger: 'blur'}
          ],
        },
        loading: 0,
        unClick: false,
        btnHoverBool: false,
        //当前页
        current: 1,
        //每页条数
        size: 10,
        //5秒定时器
        fiveTime: null
      };
    },
    beforeDestroy() {
      clearInterval(this.fiveTime)
      clearInterval(this.checkTimer)
    },
    mounted() {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
      document.body.appendChild(s);
      this.wechatLogin = this.$route.query.wechatLogin;
      this.wechatAuth = this.$route.query.wechatAuth;
      if (this.$i18n.locale === 'cn') {
        this.isCN = true
      } else {
        this.isCN = false
      }
      this.randomtoken()
    },
    computed: {
      ...mapState({
        // 专利翻译
        patentTranslation: state => state.patentTranslation,
        // 商标
        trademark: state => state.trademark,
        // 专利检索
        patentSearch: state => state.patentSearch,
        // 专利检索
        userSource: state => state.userSource
      })
    },
    methods: {
      ...mapMutations(["setMe", 'setNoticeList', 'setToken', 'ADSEARCHLOG']),
      jumpTab(){
        window.open("#/legal/term", '_blank');
      },
      handleCommand(command) {
        if(command == '86'){
          this.selectVal = command
          this.regionCodeObject.srcImg = '/images/national/zgdl.jpg'
          this.regionCodeObject.code = command
        }else if(command == '852'){
          this.selectVal = command
          this.regionCodeObject.srcImg = '/images/national/zgxg.jpg'
          this.regionCodeObject.code = command
        }else if(command == '853'){
          this.selectVal = command
          this.regionCodeObject.srcImg = '/images/national/am.jpg'
          this.regionCodeObject.code = command
        }
      },
      //PC端获取随机令牌接口
      randomtoken() {
        this.$axios
          .get("/auth/qrcode/randomtoken", {
            headers: {
              Authorization: "Basic c29jaWFsOnNvY2lhbA=="
            }
          }).then(({data}) => {
          if (data.code == 0) {
            this.generate(data.data)
          }
        })
          .catch(error => {
          });
      },
      //PC端生成小程序码
      generate(data) {
        var appid = "";
        var uriAll = window.location.href;
        var getindex = uriAll.indexOf(".cn"); //获取指定字符串在原字符串条中的位置
        var getstr = uriAll.substring(0, getindex);
        if (getstr.indexOf("test.lanternfish") != -1) {
          appid = "wx08cd11361e5592ce";
        } else {
          appid = "wx9777e2219466391c";
        }
        this.$axios
          .get("/auth/qrcode/generate", {
            params: {
              randomToken: data, // 通过获取随机令牌接口获取的
              appId: appid, // 小程序的APPID
              path: this.userSource.path, // 小程序路径，微信扫码后小程序的跳转路径 比如： pages/auth/auth  【注意pages前面不要有/反斜杠】
              source: this.userSource.name
            },
            headers: {
              Authorization: "Basic c29jaWFsOnNvY2lhbA=="
            }
          }).then(({data}) => {
          if (data.code == 0) {
            this.qrCodeImg = data.data.base64Img
            this.checkTimer = setInterval(() => {
              this.check(data.data.randomToken)
            }, 2000)
          }
          // if (data.code == 0) {
          //   console.log(data,"data==123")
          // }
        })
          .catch(error => {
          });
      },
      //小程序登录成功后通知接口
      notify(data, randomToken) {
        this.$axios("/auth/qrcode/notify", {
          method: 'post',
          params: {
            "randomToken": randomToken,
            "accessToken": data.access_token
          },
          headers: {
            Authorization: "Basic c29jaWFsOnNvY2lhbA=="
          }
        }).then(({data}) => {
          // if (data.code == 0) {
          //   console.log(data,"data==123")
          // }
        })
          .catch(error => {
          });
      },
      //PC端轮询查询小程序登录状态接口
      check(randomToken) {
        this.$axios
          .get("/auth/qrcode/check?randomToken=" + randomToken, {
            headers: {
              Authorization: "Basic c29jaWFsOnNvY2lhbA=="
            }
          }).then(({data}) => {
          if (data.code == 0) {
            clearInterval(this.checkTimer)
            // this.notify(data.data, randomToken)
            if (data.data.access_token) {
              this.setToken(data.data.access_token)
              this.$EventBus.$emit('applicant','applicant')
            } else {
              this.SqueezeOutLanding()
              return;
            }
            this.$emit("loginDialog", false);
            this.getUserInfo();
            this.pageAjax();
            this.$refs["ruleForm"].resetFields();
            this.codeMsg = this.$t("logins.form.sendCodeText");
            this.countdown = 60;
            clearInterval(this.timer);
            this.timer = null;
            this.codeDisabled = false;
            this.disabledBtn = false;
            this.loading = 0;
            this.wechatLogin = false;
            this.wechatAuth = false;
            this.unClick = false;
          }
        })
          .catch(error => {
          });
      },
      switchTable(item) {
        switch (item) {
          case 1:
            this.activeFlag = true;
            this.BackLogin();
            break;
          case 2:
            this.activeFlag = false;
            this.quicklogin();
            break;
          default:
            break;
        }
      },
      keyer() {
        let count = 0;
        clearInterval(this.fiveTime)
        this.fiveTime = setInterval(() => {
          count += 1
          if (count == 5) {
            count = 0
            clearInterval(this.fiveTime)
            this.BackLogin()
          }
        }, 1000)
      },
      SqueezeOutLanding() {
        this.$confirm('该账户已经登录，是否退出其他登陆？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.login(true)
        }).catch(() => {

        });
      },
      //用户名账号密码登录
      login(data) {
        const user = encryption({
          data: this.ruleForm,
          key: 'ipixelipixel6666',
          param: ['password']
        });
        var dataObj = qs.stringify({
          'password': user.password,
          'username': this.selectVal == '86'  ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone
        });
        this.$axios({
            url: "/auth/oauth/token",
            method: 'post',
            params: {
              checkCode: 'n',
              grant_type: 'password',
              authType: 'image'
            },
            data: dataObj,
            headers: {
              Authorization: "Basic aXBpeGVsOmlwaXhlbA=="
            }
          }).then((data) => {
          if (data.status == 200) {
            if (data.data.access_token) {
              this.setToken(data.data.access_token)
              this.$EventBus.$emit('applicant','applicant')
            } else {
              this.SqueezeOutLanding()
              return;
            }
            this.$emit("loginDialog", false);
            this.getUserInfo();
            this.pageAjax();
            this.$refs["ruleForm"].resetFields();
            this.codeMsg = this.$t("logins.form.sendCodeText");
            this.countdown = 60;
            clearInterval(this.timer);
            this.timer = null;
            this.codeDisabled = false;
            this.disabledBtn = false;
            this.loading = 0;
            this.wechatLogin = false;
            this.wechatAuth = false;
            this.unClick = false;
          } else {
            this.$message({
              message: err.data.msg
            });
          }
        })
          .catch(err => {
            clearInterval(this.timer);
            this.codeMsg = this.$t("logins.form.resendText");
            this.countdown = 60;
            this.timer = null;
            this.codeDisabled = false;
            this.disabledBtn = false;
            this.loading = 0;
            this.$message({
              message: err.data.msg
            })
          });
      },
      //立即注册
      Register() {
        this.ruleForm = {
          phone: '',
          code: '',
          password: undefined
        },
          this.userName = false
        this.quickLogin = false
        this.forgetPassword = false
        this.setPassword = false
        this.loginSuccessful = false
        this.register = true
      },
      //快捷登录
      quicklogin() {
        this.ruleForm = {
          phone: '',
          code: '',
          password: undefined
        },
          this.userName = false
        this.register = false
        this.forgetPassword = false
        this.setPassword = false
        this.loginSuccessful = false
        this.quickLogin = true
      },
      //是否可以去设置密码
      goSetpassword(formName) {
        // this.ruleForm = {
        //   phone: '',
        //   code: '',
        //   password: undefined
        // },
        // this.userName = false
        // this.register = false
        // this.quickLogin = false
        // this.forgetPassword = false
        // this.setPassword = false
        // this.loginSuccessful = true
        //是否禁用
        this.disabledBtn = true;
        setTimeout(() => {
          this.disabledBtn = false;
        }, 1000);
        this.$refs[formName].validate(result => {
          if (result) {
            this.disabledBtn = true;
            //开启loading
            this.loading++;
            this.unClick = true;
            // 直接用手机表单登录接口
            smsVerification({
              username: this.selectVal == '86' ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone,
              code: this.ruleForm.code,
              token: this.token,
              authType: "sms",
            }).then(data => {
              if (data.code == 0) {
                // this.registerSuccess = true
                this.Reiphone = this.selectVal == '86' ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone
                this.Retoken = data.data
                this.$refs[formName].resetFields();
                this.countdown = 60;
                clearInterval(this.timer);
                this.codeMsg = this.$t('logins.form.resendText');
                this.timer = null;
                this.codeDisabled = false;
                this.disabledBtn = false;
                this.loading = 0;
                this.wechatLogin = false;
                this.wechatAuth = false;
                this.unClick = false;
                this.Setpassword()
                // if (data.data.access_token) {
                //   this.setToken(data.data.access_token)
                // }
                //
                // this.$emit("loginDialog", false);
                // this.getUserInfo();
                // this.pageAjax();
                // this.$refs["ruleForm"].resetFields();
                // this.codeMsg = this.$t("logins.form.sendCodeText");
                // this.countdown = 60;
                // clearInterval(this.timer);
                // this.timer = null;
                // this.codeDisabled = false;
                // this.disabledBtn = false;
                // this.loading = 0;
                // this.wechatLogin = false;
                // this.wechatAuth = false;
                // this.unClick = false;
              }else {
                this.countdown = 60;
                clearInterval(this.timer);
                this.codeMsg = this.$t('logins.form.resendText');
                this.timer = null;
                this.codeDisabled = false;
                this.disabledBtn = false;
                this.loading = 0;
                this.wechatLogin = false;
                this.wechatAuth = false;
                this.unClick = false;
                this.$message({
                  message: data.msg
                });
              }
            })
              .catch(err => {
                clearInterval(this.timer);
                this.countdown = 60;
                this.codeMsg = this.$t('logins.form.resendText');
                this.timer = null;
                this.codeDisabled = false;
                this.disabledBtn = false;
                this.loading = 0;

              });
          }
        });
      },
      //密码修改
      RSetpasswordSuccess(formName) {
        this.$refs[formName].validate(result => {
          if (result) {
            if (this.ruleForm.password == this.ruleForm.repeatpassword) {
              retrieve({
                authType: "sms",
                password: this.ruleForm.password,
                token: this.Retoken,
                username: this.Reiphone
              }).then(res => {
                if (res.code == 0) {
                  this.SetpasswordSuccess()
                  this.keyer()
                } else {
                  this.$message('密码修改失败');
                }
              })
            } else {
              this.$message('次密码输入不一致');
            }
          } else {
            this.$message('密码格式不正确');
          }
        })

      },
      //注册是否成功
      isRegister(formName) {
        //是否禁用
        this.disabledBtn = true;
        setTimeout(() => {
          this.disabledBtn = false;
        }, 1000);
        this.$refs[formName].validate(result => {
          if (result) {
            if (this.checked) {
              this.disabledBtn = true;
              //开启loading
              this.loading++;
              this.unClick = true;
              // 直接用手机表单登录接口
              const user = encryption({
                data: this.ruleForm,
                key: 'ipixelipixel6666',
                param: ['password']
              });
              this.$axios({
                url: '/auth/user/register',
                method: 'post',
                params: {
                  subject:  'SMS@'+  (this.selectVal == '86' ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone),
                  code: this.ruleForm.code,
                  userSource: this.userSource.name,
                  invitationCode:this.ruleForm.invitationCode
                },
                data: qs.stringify({
                  password: user.password
                })
              }).then(response => {
                if (response.data.code == 0) {
                  this.registerSuccess = true
                  this.$refs[formName].resetFields();
                  this.countdown = 60;
                  this.codeMsg = this.$t('logins.form.resendText');
                  clearInterval(this.timer);
                  this.timer = null;
                  this.codeDisabled = false;
                  this.disabledBtn = false;
                  this.loading = 0;
                  this.wechatLogin = false;
                  this.wechatAuth = false;
                  this.unClick = false;
                  this.SetpasswordSuccess()

                }else {
                  clearInterval(this.timer);
                  this.countdown = 60;
                  this.codeMsg = this.$t('logins.form.resendText');
                  this.timer = null;
                  this.codeDisabled = false;
                  this.disabledBtn = false;
                  this.loading = 0;
                  this.$message({
                    message: response.data.msg
                  });
                }
              })
                .catch(err => {
                  clearInterval(this.timer);
                  this.countdown = 60;
                  this.codeMsg = this.$t('logins.form.resendText');
                  this.timer = null;
                  this.codeDisabled = false;
                  this.disabledBtn = false;
                  this.loading = 0;
                  if (err.status == 428) {
                    this.$message({
                      message: err.data.msg
                    });
                  }else{
                    this.$message({
                      message: '注册失败'
                    });
                  }
                });
            } else {
              this.$message({
                message: this.$t("logins.errorAgreeMessage")
              });
            }
          }
        });
      },
      //忘记密码
      Forgetpassword() {
        this.ruleForm = {
          phone: '',
          code: '',
          password: undefined
        },
          this.userName = false
        this.register = false
        this.quickLogin = false
        this.setPassword = false
        this.loginSuccessful = false
        this.forgetPassword = true
      },
      //设置密码
      Setpassword() {
        this.ruleForm = {
          phone: '',
          code: '',
          password: undefined
        },
          this.userName = false
        this.register = false
        this.quickLogin = false
        this.forgetPassword = false
        this.loginSuccessful = false
        this.setPassword = true
      },
      //设置修改密码成功
      SetpasswordSuccess() {
        this.ruleForm = {
          phone: '',
          code: '',
          password: undefined
        },
          this.userName = false
        this.register = false
        this.quickLogin = false
        this.forgetPassword = false
        this.setPassword = false
        this.loginSuccessful = true
      },
      //返回登录
      BackLogin() {
        this.ruleForm = {
          phone: '',
          code: '',
          password: undefined
        },
          this.register = false
        this.quickLogin = false
        this.forgetPassword = false
        this.setPassword = false
        this.loginSuccessful = false
        this.registerSuccess = false
        this.userName = true
        this.activeFlag = true;
        clearInterval(this.fiveTime)
      },
      // 修改登录方式
      switchLoginMode() {
        var appid = "";
        this.wechatLogin = !this.wechatLogin;
        var uriAll = window.location.href;
        var getindex = uriAll.indexOf(".cn"); //获取指定字符串在原字符串条中的位置
        var getstr = uriAll.substring(0, getindex);
        if (getstr.indexOf("test.lanternfish") != -1) {
          appid = "wx855ff947adfb6ef2";
        } else {
          appid = "wx6a278bb6f3d73649";
        }
        if (this.wechatLogin) {
          this.tool.setCookie("loginUrl", this.$route.path);
          var obj = new WxLogin({
            self_redirect: false,
            id: "qrcode",
            appid: appid,
            scope: "snsapi_login",
            redirect_uri: encodeURIComponent(getstr + ".cn/#/callback"),
            state: "/",
            style: "black",
            href:
              "data:text/css;base64,QGNoYXJzZXQgIlVURi04IjsNCi5pbXBvd2VyQm94e3dpZHRoOmF1dG87fQ0KLmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIyN3B4O2hlaWdodDogMjI3cHg7bWFyZ2luLXRvcDowO2JvcmRlcjpub25lO30NCi5pbXBvd2VyQm94IC50aXRsZSB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAuaW5mbyB7ZGlzcGxheTogbm9uZTt9DQouaW1wb3dlckJveCAud3JwX2NvZGV7d2lkdGg6MjI3cHg7fQ=="
          });
        } else {
          this.$refs["ruleForm"].resetFields();
          clearInterval(this.timer);
          this.codeMsg = this.$t("logins.form.sendCodeText");
          this.countdown = 60;
          this.timer = null;
          this.codeDisabled = false;
          this.disabledBtn = false;
          this.loading = 0;
        }
      },
      //
      success(params){
        // params 返回的二次验证参数, 和登录参数一起回传给登录接口，方便后台进行二次验证
        // return;
        this.getCode()
      },
      useVerify(ruleForm){
        this.flag = ruleForm
        this.$refs.verify.show()
      },
      // 获取验证码
      async getCode() {
        if (this.quickLogin) {
          let res = await loginCheck({
            authType: 'sms',
            username: this.ruleForm.phone
          })

          if (res.data == false) {
            this.isName = false
            this.$message({
              message: '您还不是蓝灯鱼用户，请先去注册'
            });
          } else {
            this.isName = true
          }
        }

        if (this.isName == false) {
          return;
        }
        this.codeDisabled = true;
        setTimeout(() => {
          this.codeDisabled = false;
        }, 1000);

        this.$refs[this.flag].validateField("phone", async phoneError => {
          if (!phoneError) {
            if (this.wechatLogin && this.wechatAuth) {
              let vxData = await VXCHECK({
                username: this.ruleForm.phone,
                authType: "sms"
              });
              if (vxData.data == true) {
                this.$confirm(
                  this.$t(`logins.confirmTips`),
                  this.$t(`logins.confirmTipsText`),
                  {
                    confirmButtonText: this.$t(`logins.confirmButtonText`),
                    cancelButtonText: this.$t(`logins.cancelButtonText`),
                    type: "warning"
                  }
                ).then(data => {
                  if (data == "confirm") {
                    this.$router.push({
                      query: {
                        wechatLogin: false,
                        wechatAuth: false
                      }
                    })
                    this.wechatLogin = false;
                    this.wechatAuth = false;
                    this.code = "";
                  } else {
                    this.$router.push(this.tool.getCookie("loginUrl"));
                  }
                });
              } else {
                // 验证码60秒倒计时
                if (!this.timer) {
                  this.timerEvent();
                }
              }
            } else {
              // 验证码60秒倒计时
              if (!this.timer) {
                this.timerEvent();
              }
            }
          }
        });
      },

      // 倒计时
      timerEvent() {
        this.smsAjax();
      },
      // 验证码接口
      async smsAjax() {
        SMS('SMS@' + (this.selectVal == '86' ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone)).then(data => {
          if(data.code == 0 && data.data) {
            this.codeDisabled = true;
            this.timer = setInterval(() => {
              if (this.countdown > 0 && this.countdown <= 60) {
                this.countdown--;
                if (this.countdown !== 0) {
                  this.codeMsg = this.countdown + " s";
                } else {
                  clearInterval(this.timer);
                  this.codeMsg = this.$t("logins.form.resendText");
                  this.countdown = 60;
                  this.timer = null;
                  this.codeDisabled = false;
                }
              }
            }, 1000);
            this.$message({
              message: '验证码发送成功'
            });
          }else {
            this.$message({
              message: data.msg
            });
            clearInterval(this.timer);
            this.codeMsg = this.$t("logins.form.resendText");
            this.countdown = 60;
            this.timer = null;
            this.codeDisabled = false;
          }
        }).catch(err => {
          this.$message({
            message: '发送失败，检查手机号是否正确'
          });
          clearInterval(this.timer);
          this.codeMsg = this.$t("logins.form.resendText");
          this.countdown = 60;
          this.timer = null;
          this.codeDisabled = false;
        });

      },
      //获取高级检索
      async adSearchLog() {
        let data = await adSearchLog({
          patentSearchType: 'SUPER',
          productType: '专利',
          current: this.current,
          size: this.size
        })
        if (data.code == 0) {
          this.tableData = data.data.records
          this.total = data.data.total
        }
      },
      // 表单提交
      submitForm(formName) {
        this.disabledBtn = true;
        setTimeout(() => {
          this.disabledBtn = false;
        }, 1000);
        this.$refs[formName].validate(result => {
          if (result) {
            this.disabledBtn = true;
            this.loading++;
            this.unClick = true;
            if(formName == 'ruleForm2') {
              if (this.checked) {
                if (this.wechatLogin && this.wechatAuth) {
                  //使用微信绑定手机表单登陆。
                  this.$axios({
                    url: '/auth/user/regist',
                    method: 'post',
                    params: {
                      username: this.ruleForm.phone,
                      code: this.ruleForm.code,
                      token: this.token,
                      authType: "sms",
                      userSource: this.userSource.name
                    },
                    data: {
                      password: this.ruleForm.code,
                      openId: this.tool.getCookie("openId"),
                      nickName: this.tool.getCookie("nickName"),
                      grant_type: "mobile",
                      scope: "server"
                    }
                  }).then(async data => {
                    if (data.data.code == 0) {
                      if (data.data.data.access_token) {
                        this.setToken(data.data.data.access_token)
                        this.$EventBus.$emit('applicant','applicant')
                      }
                      this.$emit("loginDialog", false);
                      this.getUserInfo();
                      this.pageAjax();
                      this.$refs["ruleForm"].resetFields();
                      this.codeMsg = this.$t("logins.form.sendCodeText");
                      this.countdown = 60;
                      clearInterval(this.timer);
                      this.timer = null;
                      this.codeDisabled = false;
                      this.disabledBtn = false;
                      this.loading = 0;
                      this.wechatLogin = false;
                      this.wechatAuth = false;
                      this.unClick = false;
                    }
                  })
                    .catch(err => {
                      clearInterval(this.timer);
                      this.codeMsg = this.$t("logins.form.resendText");
                      this.countdown = 60;
                      this.timer = null;
                      this.codeDisabled = false;
                      this.disabledBtn = false;
                      this.loading = 0;
                      if (err.status == 428) {
                        this.$message({
                          message: err.data.msg
                        });
                      }else{
                        this.$message({
                          message: '注册失败'
                        });
                      }
                    });
                }
              } else {
                this.$message({
                  message: this.$t("logins.errorAgreeMessage")
                });
              }
            }else {
              // 直接用手机表单登录接口
              this.$axios({
                url: '/auth/oauth/token',
                headers: {
                  Authorization: "Basic " + this.userSource.token
                },
                method: 'get',
                params: {
                  subject: 'SMS@'+  (this.selectVal == '86' ? this.ruleForm.phone : this.selectVal + this.ruleForm.phone),
                  code: this.ruleForm.code,
                  grant_type: "mobile"
                }
              }).then(async data => {
                if (data.status == 200) {
                  if (data.data.access_token) {
                    this.setToken(data.data.access_token)
                    this.$EventBus.$emit('applicant','applicant')
                  }

                  this.$emit("loginDialog", false);
                  this.getUserInfo();
                  this.pageAjax();
                  this.$refs["ruleForm"].resetFields();
                  this.codeMsg = this.$t("logins.form.sendCodeText");
                  this.countdown = 60;
                  clearInterval(this.timer);
                  this.timer = null;
                  this.codeDisabled = false;
                  this.disabledBtn = false;
                  this.loading = 0;
                  this.wechatLogin = false;
                  this.wechatAuth = false;
                  this.unClick = false;
                }
              })
                .catch(err => {
                  clearInterval(this.timer);
                  this.codeMsg = this.$t("logins.form.resendText");
                  this.countdown = 60;
                  this.timer = null;
                  this.codeDisabled = false;
                  this.disabledBtn = false;
                  this.loading = 0;
                  this.$message({
                    message: err.data.msg
                  });
                });
            }
          }
        });
      },
      // 获取用户信息
      async getUserInfo() {
        let user = await USER();
        if (user.code == 0) {
          this.setMe(user.data)
          this.$EventBus.$emit('load', '1')
          // window.location.reload();

          setTimeout(() => {
            // window.location.reload([true])
          }, 500)

          //专利日志的获取
          // let res = await adSearchLog({
          //   patentSearchType:'SUPER',
          //   productType:'专利',
          //   current: this.current,
          //   size: this.size
          // })
          //
          // if(res.code == 0){
          //   this.$store.commit('ADSEARCHLOG',res.data)
          // }

        }
      },
      // 获取通知消息
      async pageAjax() {
        let notify = await NOTIFY();
        if (notify.code == 0) {
          this.setNoticeList(notify.data.records)
        }
      }
    },
    watch: {
      dialogClose(param) {
        if (param) {
          this.$refs["ruleForm"].resetFields();
          clearInterval(this.timer);
          clearInterval(this.checkTimer)
          this.codeMsg = this.$t("logins.form.sendCodeText");
          this.countdown = 60;
          this.timer = null;
          this.codeDisabled = false;
          this.disabledBtn = false;
          this.loading = 0;
          this.wechatLogin = false;
          this.wechatAuth = false;
          this.$emit("dialogCloseBool", false);
        }
      }
    }
  };
</script>

<style lang='less' scoped>
  .login_wrap {
    position: relative;
    .loginSuccessful {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      top: -250px;

      i {
        margin-top: 20px;
        width: 75px;
        height: 75px;
        border: 1px solid #ebeef5;
        box-shadow: -1px 1px 12px 2px rgba(0, 0, 0, 0.21);
        -webkit-box-shadow: -1px 1px 12px 2px rgba(0, 0, 0, 0.21);
        -moz-box-shadow: -1px 1px 12px 2px rgba(0, 0, 0, 0.21);
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 50%;
      }

      span {
        margin-top: 30px;
        font-weight: bold;
        font-size: 20px;
        color: #979596;
      }

      .login_btn {
        width: 260px;
        height: 45px;
        border-radius: 5px;
        background: @blue;
        color: #fff;
        border: none;
        margin: 20px 0 10px;
        font-size: 16px;

        &:hover {
          box-shadow: @boxShadow;
        }
      }
    }

    .accout {
      text-align: center;
      position: relative;
      bottom: -180px;
      left: 40px;

      p{
        font-size: 13px;
        line-height: 13px;
      }

      span {
        color: @blue;
        cursor: pointer;
      }
    }

    width: 683px;
    height: 430px;
    //   height: 500px;
    background: #fff url("@{imgUrl}login/new_banner.png") no-repeat;
    border-radius: 5px;
    padding-top: 160px;
    box-sizing: border-box;
    position: relative;

    .login_logo {
      box-sizing: border-box;
      width: 110px;
      position: absolute;
      left: 39px;
      top: 19px;

      img {
        width: 100%;
      }
    }

    .qrCode {
      width: 146px;
      height: auto;
      position: absolute;
      text-align: center;
      top: 155px;
      left: 64px;
      .translate {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 146px;
        height: 147px;
        padding: 10px;
        box-sizing: border-box;
        box-shadow: 0px 0px 7px 1px rgba(11, 29, 52, 0.18);
      }

      .scan {
        margin-top: 18px;
        font-size: 12px;
        font-weight: 500;
        color: #666666;
      }

      .signIn {
        margin-top: 11px;
        font-size: 12px;
        font-weight: 500;
        color: #555555;
      }
    }

    .login_phone {
      height: 280px;
      width: 328px;
      position: absolute;
      right: 69px;
      bottom: 45px;
      &.active {
        bottom: 80px;
      }
      .menu {
        display: flex;
        height: 28px;

        li {
          cursor: pointer;
          width: 200px;
          /*text-align: center;*/
          line-height: 28px;
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          padding-left: 25px;
          box-sizing: border-box;

          &.active {
            background: url("@{imgUrl}login/select.png") 0% 150% no-repeat;
          }
        }
      }

      .logon_main {
        height: 252px;
        width: 100%;
        border: 2px solid #D3DBF8;
        opacity: 0.78;
        border-radius: 10px;

        &.active {
          height: 395px;
        }
      }
    }

    .login_form {
      /*padding: 0 55px;*/
      margin: 0 auto;
      width: 262px;
      margin-top: 25px;

      /*.regionCode {*/
      /*  /deep/.el-input-group__prepend{*/

      /*  }*/
      /*}*/

      /deep/.el-input-group__prepend {
        /*width: 75px;*/
        /*padding-left: 24px;*/
        border-right: 1px solid #DCDFE6;
        padding: 0 5px;
      }

      .login_code {
        position: relative;

        .login_code_btn {
          position: absolute;
          top: 0;
          right: 18px;
          background: #fff;
          color: #888;
          border: none;
          padding: 12px 0;

          &:hover {
            color: @blue;
          }

          &.is-disabled {
            color: #dce8fb;
          }
        }
      }

      .login_btn {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        background: @blue;
        color: #fff;
        border: none;
        margin: 20px 0 10px;
        font-size: 16px;

        &:hover {
          box-shadow: @boxShadow;
        }
      }

      .treaty {
        &.active {
          /deep/.el-form-item__content {
            display: flex;
          }
        }
        ul {
          margin-top: 20px;
          display: flex;
          justify-content: center;
          line-height: 1.2;

          li {
            padding: 0 12px;
            /*border-right: 2px solid #acaaad;*/
            font-size: 14px;
            font-weight: 400;
            color: #2F56DE;
            cursor: pointer;

            &:nth-of-type(3) {
              border: none;
            }

            &:hover {
              color: @blue;
            }
          }
        }

        line-height: 0;
        margin-top: -35px;

        a,
        p {
          color: #c2c2c2;
          font-size: 12px;
        }

        a {
          margin-left: -20px;

          &:hover {
            color: #888;
          }
        }

        p {
          line-height: 1.8;
        }

        &.active {
          ul {
            li {
              text-align: center;
              word-break: break-word;
              padding: 0 4px !important;
            }
          }
        }
      }
    }

    .login_wechat_btn {
      margin-top: 30px;
      cursor: pointer;

      .weChat_icon {
        margin: 0 auto;
        width: 38px;
      }

      .phone_icon {
        margin: 0 auto;
        width: 30px;
      }

      &.unclick {
        cursor: default;
      }
    }

    .login_wechat {
      dt {
        width: 230px;
        height: 230px;
        margin: 0 auto;
        border: 1px solid #e7e7e7;
        box-sizing: border-box;
        overflow: hidden;
      }

      dd {
        font-size: 14px;
        color: #c2c2c2;
        margin-top: 18px;
        text-align: center;
      }
    }
  }
</style>
<style lang="less">
  .login_wrap {
    .el-input__inner {
      border: none;
      padding: 0;
      border-radius: 0;
      border-bottom: 1px solid #dbdcdd;
      font-size: 14px;
      color: #888;
      // margin-bottom: 40px;
    }

    .el-form-item__error {
      padding-left: 15px;
    }

    .el-form {
      margin-top: 20px;
    }

    .el-checkbox {
      margin-right: 30px;
    }

    #qrcode {
      img {
        width: 100%;
      }
    }
  }
</style>

